import envDev from './env.dev';
import envProd from './env.prod';

const { NODE_ENV } = process.env;
const isProd = NODE_ENV === 'production';

export default Object.assign(
  {
    // Core environment properties.
    isDev: !isProd,
  },
  {
    // Add non-environment specific defaults here
    theme: 'default',
  },
  isProd ? envProd : envDev,
  {
    brand: {
      logo: '/assets/img/logo.png',
      paymentsuccess:'/assets/img/PaymentSuc.png',
      paymentfailed:'/assets/img/credit-card.png'
    },
    sitekey: '6LcTbMsZAAAAACVmej2wxuMvXFK9Xi2F6m93OIRq',
    secretkey : '6LcTbMsZAAAAAPQKLXsPkWy76Qx_fVk5y7BLiNqy',
  },
);

