import { DeliveryService } from '@/services/delivery-service';
import { Module, VuexModule, Mutation, Action } from 'vuex-module-decorators';

const MODULE_NAME = 'delivery-store';
@Module({ namespaced: true })


class DeliveryStore {

    public static readonly getModule = () => ({
        [MODULE_NAME]: DeliveryStore as any as VuexModule,
    })
    // --

    private _deliveryStoreVal: any[] = [];
    private _delivery: any;
    private _payment: any;
    private _order: any;
    // ------------------------------------------------------------------------
    // Getters retrieve properties from the Store.
    // ------------------------------------------------------------------------

    public get deliveryStoreVal() {
        return this._deliveryStoreVal;
    }

    public get delivery() {
        return this._delivery;
    }

    public get payment() {
        return this._payment;
    }

    public get order() {
        return this._order;
    }
    // ------------------------------------------------------------------------
    // Actions are publicly accessbile wrappers to perform mutations
    // on the Store. These actions will internally call the appropriate
    // mutations to update the Store.
    //
    // Note: The returned value will be passed to the mutation handler
    // specified as the decorator's "commit" attribute.
    // ------------------------------------------------------------------------

    @Action({ commit: 'setDeliveryStoreVal' })
    public initializeDeliveryStore(data: any) {

        const result = DeliveryService.PostData(data);
        return result;
    }

    
    @Action({ commit: 'initializeOrders' })
    public initializeOrders(data: any,code:any) {

        const result = DeliveryService.PostOrders(data,code);
        return result;
    }

    @Action({ commit: 'initializePayment' })
    public fetchPayment(id: any) {

        const result = DeliveryService.PostPayment(id);
        return result;
    }

    @Action({ commit: 'setfetchOrders' })
    public fetchOrder(id: any) {

        const result = DeliveryService.fethOrders(id);
        return result;
    }

    @Action({ commit: 'setDeliveryStoreVal' })
    public fetchDeliveryStore(id: number) {

        const result = DeliveryService.getByUserId(id);
        return result;
    }

    @Action({ commit: 'setDelivery' })
    public fetchOneDelivery(id: number) {
        const result = DeliveryService.getById(id);
        return result;
    }



    // ------------------------------------------------------------------------
    // Mutations update the properties in the Store.
    // They are internal
    // ------------------------------------------------------------------------

 
    @Mutation
    private initializePayment(value: any) {
        this._payment = value;
    }

    @Mutation
    private setfetchOrder(value: any) {
        this._order = value;
    }

    
    @Mutation
    private setDeliveryStoreVal(value: any[]) {
        this._deliveryStoreVal = value;
    }


    @Mutation
    private setDelivery(value: any) {
        this._delivery = value;
    }
}

export {
    DeliveryStore as default,
    DeliveryStore,
};
